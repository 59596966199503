import React from "react";
import Styles from './Progressbar.module.css'
const Progressbar = ({ value }) => {
    // Function to determine the progress bar color
    const getProgressColor = (value) => {
      if (value >= 80) return "#8ADF5A"; // Green for high values
      if (value >= 50) return "orange"; // Orange for moderate values
      return "#F54F4F"; // Red for low values
    };
  
    return (
      <div className={Styles.progressBarContainer}>
        <div
          className={Styles.progressBar}
          style={{
            width: `${value}%`,
            backgroundColor: getProgressColor(value),
          }}
        >
         
        </div>
      </div>
    );
  };

export default Progressbar;