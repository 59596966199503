//best best best written code
import { AgoraVideoPlayer } from "agora-rtc-react";
import { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './video.css';
import mic from '../src/assets/images/mic-control.svg'
import microphoneSlash from './assets/images/microphone-slash-solid.svg'
import microphone from './assets/images/microphone-solid.svg'
import maximize from './assets/images/up-right.svg'
import minimize from './assets/images/window-minimize-solid.svg'

export default function Video({users, tracks}) {

  // const { users, tracks } = props;
  const usersCount = users.length;

  const [layoutClass, setLayoutClass] = useState("");
  const videoContainersRef = useRef({});
  //const [trackState, setTrackState] = useState({audio: false})
  const [mutedUsers, setMutedUsers] = useState({});
  const [isMaximized, setIsMaximized] = useState(false)

  const firstUserUID = useRef(null)

  const handleAudioToggle = (audioTrack, userId) => {
    if (!audioTrack || !audioTrack.stop || !audioTrack.play) {
      console.error("No valid audio track available for this user.");
      return;
    }
  
    setMutedUsers((prevMutedUsers) => {
      const newMutedState = !prevMutedUsers[userId]; // Toggle mute state
  
      if (newMutedState) {
        audioTrack.stop(); // Mute user
      } else {
        audioTrack.play(); // Unmute user
      }
  
      return { ...prevMutedUsers, [userId]: newMutedState }; // Preserve other users' mute states
    });
  };
  
  useEffect(() => {
    if (users.length > 0 && !firstUserUID.current) {
      firstUserUID.current = users[0].uid; // Store the first user's UID
    }
  }, [users]);

  //best working mute /unmute for only one user
  // const handleAudioToggle = (audioTrack, userId) => {
  //   if (!audioTrack || !audioTrack.stop || !audioTrack.play) {
  //     console.error("No valid audio track available for this user.");
  //     return;
  //   }
  
  //   const newMuteState = !mutedUsers[userId]; // Toggle mute state
  
  //   if (newMuteState) {
  //     audioTrack.stop(); // Mute remote user
  //   } else {
  //     audioTrack.play(); // Unmute remote user
  //   }
  
  //   setMutedUsers((prev) => ({ ...prev, [userId]: newMuteState })); // Update state
  // };
  
  
  useEffect(() => {
    // Set layout class based on number of users
    if (users.length === 1) setLayoutClass("single-user");
    else if (users.length === 2) setLayoutClass("two-users");
    else if (users.length === 3) setLayoutClass("three-users");
    else if (users.length === 4) setLayoutClass("four-users");
    else if (users.length === 5) setLayoutClass("five-users");
    else setLayoutClass("");
  }, [users.length]);



   // Function to handle video size adjustments
   const handleVideoLoaded = (uid) => {
    const container = videoContainersRef.current[uid];
    if (!container) return;
    
    // Find Agora's video element inside our container
    const videoElement = container.querySelector('video');
    if (!videoElement) return;
    
    // Apply custom styles directly to Agora's video element
    videoElement.style.objectFit = 'contain';
    videoElement.style.width = '100%';
    videoElement.style.height = '100%';
    videoElement.style.backgroundColor = '#000';
  };

  
  useEffect(() => {
    const interval = setInterval(() => {
      users.forEach(user => {
        if (user.videoTrack && videoContainersRef.current[user.uid]) {
          handleVideoLoaded(user.uid);
        }
      });
    }, 1000);
    
    return () => clearInterval(interval);
  }, [users]);

  return (
    <div className={`video-grid-responsive ${layoutClass} ${isMaximized ? "maximized": ""}`}>
      
      {users.length > 0 &&
        users.map((user, index) => {
          console.log("User:", user.uid, "Has video track:", !!user.videoTrack);
          console.log("User:", user.uid, "Has audio track:", !!user.audioTrack);
          if (user.videoTrack) {
            return (
              <>
              {/* <div className="video-controls">
               <img src={mic}/>
              </div> */}
              <div
                className={`video-container-responsive user-${index + 1}`}
                key={user.uid}
                ref={el => videoContainersRef.current[user.uid] = el}
              >
                {index === 0 && users.length > 1 && (<div
                className="maximize-btn" 
                 onClick={()=> setIsMaximized((prev)=> !prev)}>
                  <div className="maximize-minimize-container">
                  {isMaximized ? <img style={{height: '12px'}} src= {minimize}/> : <img style={{height: '12px'}} src={maximize}/>}
                  </div>
                 
                </div>)}
                  
            <div
              className="video-controls"
              onClick={() => handleAudioToggle(user.audioTrack, user.uid)}
              style={{right: users.length > 1 ? '2.5%':'2.5%'}}
            >
              <img style={{height: '25px'}}
                src={mutedUsers[user.uid] ? microphoneSlash : microphone}
                alt="Mic Control"

              />
            </div>
          
              <div className="userNames">Test </div>
                <AgoraVideoPlayer
                  videoTrack={user.videoTrack}
                  className="agora-video-responsive"
                  onPlaying={() => handleVideoLoaded(user.uid)}
                />
               
              </div>
              </>
            );
          } else return null;
        })}
    </div>
  );
}













// import { AgoraVideoPlayer } from "agora-rtc-react";
// import { useEffect, useState } from "react";
// import './video.css';

// export default function Video(props) {
//   const { users, tracks } = props;
//   const usersCount = users.length;
//   console.log('usersCount', usersCount)

//   const [layoutClass, setLayoutClass] = useState("");
  
//   useEffect(() => {
//     // Set layout class based on number of users
//     if (users.length === 1) setLayoutClass("single-user");
//     else if (users.length === 2) setLayoutClass("two-users");
//     else if (users.length === 3) setLayoutClass("three-users");
//     else if (users.length === 4) setLayoutClass("four-users");
//     else if (users.length === 5) setLayoutClass("five-users");
//     else setLayoutClass("");
//   }, [users.length]);

//   return (
//     <div className={`video-grid-responsive ${layoutClass}`}>
//       {users.length > 0 &&
//         users.map((user, index) => {
//           console.log("User:", user.uid, "Has video track:", !!user.videoTrack);
//           if (user.videoTrack) {
//             return (
//               <div
//                 className={`video-container-responsive user-${index + 1}`}
//                 key={user.uid}
//               >
//                 <AgoraVideoPlayer
//                   videoTrack={user.videoTrack}
//                   className="agora-video-responsive"
                
//                 />
//               </div>
//             );
//           } else return null;
//         })}
//     </div>
//   );
// }








// import { AgoraVideoPlayer } from "agora-rtc-react";
// import { useState, useEffect } from "react";
// import './video.css';

// export default function Video(props) {
//   const { users, tracks } = props;

//   return (
//     <div className="video-grid-stack">
//       {users.length > 0 &&
//         users.map((user, index) => {
//           if (user.videoTrack) {
//             return (
//               <div
//                 className="video-container-stack"
//                 key={user.uid}
//               >
//                 <AgoraVideoPlayer
//                   videoTrack={user.videoTrack}
//                   className="agora-video-stack"
//                 />
//               </div>
//             );
//           } else return null;
//         })}
//     </div>
//   );
// }