// import React, { useEffect, useRef, useState } from 'react';
// import socketIOClient from 'socket.io-client';
// import { SOCKET_ENDPOINT } from '../config/keys.config';
// import { SOCKET_AUTHENTICATION , SOCKET_LIVELOCATION } from '../constants/socket';
// import constants from '../constants/socket';

// const GoogleMap = ({ userDetails }) => {
//     const mapRef = useRef(null);
//     const markerRef = useRef(null);
//     const mapInstanceRef = useRef(null);
//     const socketRef = useRef(null);
//     const [socketData, setSocketData] = useState(null);
//     console.log('socketData', socketData)

//     // Initialize socket connection
//     useEffect(() => {
//         console.log('Connecting to socket at:', SOCKET_ENDPOINT);
        
//         socketRef.current = socketIOClient(SOCKET_ENDPOINT, {
//             path: '/api/socket.io',
//             reconnectionAttempts: 5,
//             transports: ['websocket', 'polling'] // Explicitly specify transport methods
//         });

//         socketRef.current.on('connect', () => {
//             console.log('Socket connected successfully');
            
//             socketRef.current.emit(SOCKET_AUTHENTICATION, {
//                 token: constants.socketToken
//             });
//             console.log('Authentication event emitted');
//         });

        
//         socketRef.current.on('connect_error', (error) => {
//             console.error('Socket connection error:', error);
//         });
        
//         return () => {
//             console.log('Cleaning up socket connection');
//             if (socketRef.current) {
//                 socketRef.current.disconnect();
//             }
//         };
//     }, [socketData]);

   
//  useEffect(()=>{
//     socketRef.current.on(SOCKET_LIVELOCATION, (data) => {
//         console.log('Received live location data:', data);
//         setSocketData(data);
//     });
//  },[socketData])

   
//     useEffect(() => {
//         if (!mapRef.current) return;

//         const defaultCoordinates = { lat: 33.6844, lng: 73.0479 };
//         const initialCoordinates = userDetails?.iyzilDetail?.coordinates
//             ? {
//                 lat: parseFloat(userDetails.iyzilDetail.coordinates.lat),
//                 lng: parseFloat(userDetails.iyzilDetail.coordinates.lng),
//             }
//             : defaultCoordinates;

//         // Initialize map only if it hasn't been initialized
//         if (!mapInstanceRef.current) {
//             console.log('Initializing map with coordinates:', initialCoordinates);
//             mapInstanceRef.current = new window.google.maps.Map(mapRef.current, {
//                 center: initialCoordinates,
//                 zoom: 12,
//             });

//             markerRef.current = new window.google.maps.Marker({
//                 position: initialCoordinates,
//                 map: mapInstanceRef.current,
//                 title: 'User Location',
//             });
//         }

//         // Update marker position if on basis socket data
//         if (socketData) {
//             const { latitude, longitude } = socketData;
//             if (latitude && longitude) {
//                 console.log('Updating marker position to:', latitude, longitude);
//                 const updatedPosition = {
//                     lat: parseFloat(latitude),
//                     lng: parseFloat(longitude)
//                 };
                
//                 if (markerRef.current && mapInstanceRef.current) {
//                     markerRef.current.setPosition(updatedPosition);
//                     mapInstanceRef.current.panTo(updatedPosition);
//                     console.log('Marker and map updated successfully');
//                 }
//             }
//         }
//     }, [userDetails, socketData]);

//     return (
//         <div
//             ref={mapRef}
//             style={{
//                 width: '100%',
//                 height: '100%',
//                 border: '1px solid #ececec',
//             }}
//         />
//     );
// };

// export default GoogleMap;









import React, { createContext, use, useEffect, useRef, useState } from 'react';
//import socketIOClient from 'socket.io-client';
import { io } from 'socket.io-client';
import { SOCKET_ENDPOINT } from '../config/keys.config';
import { SOCKET_AUTHENTICATION, SOCKET_LIVELOCATION } from '../constants/socket';
import constants from '../constants/socket';
import { useSocket } from '../context/socket-context';
import { query } from 'firebase/database';
import socketIOClient from 'socket.io-client';

const GoogleMap = ({ userDetails, onLocationUpdate }) => {
    console.log('userDetailsinGoogleMap', userDetails)
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const mapInstanceRef = useRef(null);
    const socketRef = useRef(null);
    const [socketData, setSocketData] = useState(null);
    console.log('socketData', socketData)
    const [socket, setSocket] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [sockat, setSockat] = useState(null)
    
    console.log('sockatt', sockat)
   
    const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
    const queryParams = new URLSearchParams(window.location.search); // Get the query string
    const alarmId = queryParams.get('alarmId');
    
  const latitudeForMap = userDetails?.iyzilDetail?.coordinates?.lat;
  const longitudeForMap = userDetails?.iyzilDetail?.coordinates?.lng;


    // const newSocket = socketIOClient(SOCKET_ENDPOINT, {
    //     path: '/api/socket.io',
    //     reconnectionAttempts: 5,
    //     transports: ['websocket']
    //  })
        
    // Check if Google Maps is loaded
    useEffect(() => {
        const checkGoogleMapsLoaded = () => {
            if (window.google && window.google.maps) {
                setIsGoogleMapsLoaded(true);
            } else {
                console.warn('Google Maps not loaded yet, retrying...');
                setTimeout(checkGoogleMapsLoaded, 500);
            }
        };
        
        checkGoogleMapsLoaded();
    }, []);

   
    // Initialize socket connection 
    //comment for style changing

    
      const connectToSocket=()=>{
        try {
            const newSocket = socketIOClient(SOCKET_ENDPOINT, {
                path: '/api/socket.io',
                reconnectionAttempts: 5,
                transports: ['websocket'],
                query: { token: "89a3da5810da0c14867a1718b52248feb1a19ea83a4e17681a85ceaea15ee184" },
             })
         
            newSocket.on('connect', () => {
                console.log('Socket connected successfully');
                setSockat(newSocket)
                setSocket(true)
               
                newSocket.emit(SOCKET_AUTHENTICATION, {
                    token: "89a3da5810da0c14867a1718b52248feb1a19ea83a4e17681a85ceaea15ee184"
                });
                console.log('Authentication event emitted');
               
            });
            
            newSocket.on('connect_error', (error) => {
                console.error('Socket connection error:', error);
            });

            newSocket.on(`${SOCKET_LIVELOCATION}:${alarmId}`, (data) => {
                console.log('Received live location data:', data);
               
                setSocketData(data);
            });
            // newSocket.on(`${SOCKET_LIVELOCATION}:${alarmId}`, (data) => {
            //     console.log('Received live location data:', data);
            //     const updatedLocation ={
            //         latitude: parseFloat(data.latitude),
            //         longitude: parseFloat(data.longitude)
            //     }
            //     onLocationUpdate(updatedLocation)
            //     setSocketData(data);
            // });
    
            newSocket.on('disconnect',()=>{
                console.log('i am disconnecting socket')
                return ()=>{
                    newSocket.disconnect()
                    //newSocket.off(`${SOCKET_LIVELOCATION}`)
                }
            })
          
           
           
       
            } catch (error) {
                console.log('error', error)
            }
      }
    
      useEffect(()=>{
        connectToSocket()
      },[])
      
    useEffect(()=>{
        if(sockat && socketData){
                const updatedLocation ={
                    latitude: parseFloat(socketData.latitude),
                    longitude: parseFloat(socketData.longitude)
                }
                onLocationUpdate(updatedLocation)
        }
    },[socketData])
  




    // useEffect(()=>{
    //     sockat.on(`${SOCKET_LIVELOCATION}:${alarmId}`, (data) => {
    //         console.log('Received live location data:', data);
    //         const updatedLocation ={
    //             latitude: parseFloat(data.latitude),
    //             longitude: parseFloat(data.longitude)
    //         }
    //         onLocationUpdate(updatedLocation)
    //         setSocketData(data);
    //     });
    // },[socketData])
    
    
    //BEST WORKING USEEFFECT ok !!!!!!
    // useEffect(() => {
    //     if (!mapRef.current || !isGoogleMapsLoaded) return;

    //     try {
    //         const defaultCoordinates = { lat: 33.6844, lng: 73.0479 };
    //         const initialCoordinates = userDetails?.iyzilDetail?.coordinates
    //             ? {
    //                 lat: parseFloat(userDetails.iyzilDetail.coordinates.lat),
    //                 lng: parseFloat(userDetails.iyzilDetail.coordinates.lng),
    //             }
    //             : defaultCoordinates;

    //         // Initialize map only if it hasn't been initialized
    //         if (!mapInstanceRef.current) {
    //             console.log('Initializing map with coordinates:', initialCoordinates);
    //             mapInstanceRef.current = new window.google.maps.Map(mapRef.current, {
    //                 center: initialCoordinates,
    //                 zoom: 12,
    //             });

    //             markerRef.current = new window.google.maps.Marker({
    //                 position: initialCoordinates,
    //                 map: mapInstanceRef.current,
    //                 title: 'User Location',
    //             });
    //         }

    //         // Update marker position based on socket data
    //         if (socketData) {
    //             const { latitude, longitude } = socketData;
    //             if (latitude && longitude) {
    //                 console.table('latitude in marker', latitude)
    //                 console.log('Updating marker position to:', latitude, longitude);
    //                 const updatedPosition = {
    //                     lat: parseFloat(latitude),
    //                     lng: parseFloat(longitude)
    //                 };
                    
    //                 if (markerRef.current && mapInstanceRef.current) {
    //                     markerRef.current.setPosition(updatedPosition);
    //                     mapInstanceRef.current.panTo(updatedPosition);
    //                     console.log('Marker and map updated successfully');
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error initializing/updating map:', error);
    //     }
    // }, [userDetails, socketData, isGoogleMapsLoaded]);



    useEffect(() => {
        if (!mapRef.current || !isGoogleMapsLoaded) return;
    
        try {
            // Check if userDetails is available
            if (!userDetails?.iyzilDetail?.coordinates) {
                console.log("Waiting for user details to load...");
                return; // Don't initialize the map yet
            }
    
            const initialCoordinates = {
                lat: parseFloat(userDetails.iyzilDetail.coordinates.lat),
                lng: parseFloat(userDetails.iyzilDetail.coordinates.lng),
            };
    
            // Introduce a delay to ensure API response is available
            setTimeout(() => {
                if (!mapInstanceRef.current) {
                    console.log("Initializing map with coordinates:", initialCoordinates);
                    mapInstanceRef.current = new window.google.maps.Map(mapRef.current, {
                        center: initialCoordinates,
                        zoom: 12,
                    });
    
                    markerRef.current = new window.google.maps.Marker({
                        position: initialCoordinates,
                        map: mapInstanceRef.current,
                        title: "User Location",
                    });
                }
            }, 1500); // 1.5 seconds delay to wait for API data
    
            // Smoothly update marker position based on socket data
            if (socketData) {
                const { latitude, longitude } = socketData;
                if (latitude && longitude) {
                    console.log("Updating marker position to:", latitude, longitude);
                    const updatedPosition = {
                        lat: parseFloat(latitude),
                        lng: parseFloat(longitude),
                    };
    
                    if (markerRef.current && mapInstanceRef.current) {
                        const animateMarker = (currentPosition, targetPosition, step = 0.05) => {
                            const lat = currentPosition.lat + (targetPosition.lat - currentPosition.lat) * step;
                            const lng = currentPosition.lng + (targetPosition.lng - currentPosition.lng) * step;
    
                            const interpolatedPosition = { lat, lng };
                            markerRef.current.setPosition(interpolatedPosition);
    
                            if (Math.abs(lat - targetPosition.lat) > 0.0001 || Math.abs(lng - targetPosition.lng) > 0.0001) {
                                requestAnimationFrame(() => animateMarker(interpolatedPosition, targetPosition, step));
                            } else {
                                markerRef.current.setPosition(targetPosition); // Ensure the marker reaches the final position
                            }
                        };
    
                        // Get current marker position
                        const currentMarkerPosition = {
                            lat: markerRef.current.getPosition().lat(),
                            lng: markerRef.current.getPosition().lng(),
                        };
    
                        // Animate marker to the updated position
                        animateMarker(currentMarkerPosition, updatedPosition);
    
                        console.log("Marker and map updated smoothly");
                    }
                }
            }
        } catch (error) {
            console.error("Error initializing/updating map:", error);
        }
    }, [userDetails, socketData, isGoogleMapsLoaded]);
    




    // useEffect(() => {
    //     if (!mapRef.current || !isGoogleMapsLoaded) return;
    
    //     try {
          
    //         const defaultCoordinates = { lat: 33.23323, lng: 71.234334 };
    //         const initialCoordinates = userDetails?.iyzilDetail?.coordinates
    //         ? {
    //             lat: parseFloat(userDetails.iyzilDetail.coordinates.lat),
    //             lng: parseFloat(userDetails.iyzilDetail.coordinates.lng),
    //         }  : defaultCoordinates
    //         //     // socketData? {lat: parseFloat(socketData.latitude),
    //         //     //     lng: parseFloat(socketData.longitude)
    //         //     // }:''
              
    //         // // Initialize map only if it hasn't been initialized
    //         if (!mapInstanceRef.current) {
    //             console.log('Initializing map with coordinates:', initialCoordinates);
    //             mapInstanceRef.current = new window.google.maps.Map(mapRef.current, {
    //                 center: initialCoordinates,
    //                 zoom: 12,
    //             });
                
    //             markerRef.current = new window.google.maps.Marker({
    //                 position: initialCoordinates,
    //                 map: mapInstanceRef.current,
    //                 title: 'User Location',
    //             });
    //         }
    
    //         // Smoothly update marker position based on socket data
    //         if (socketData) {
    //             const { latitude, longitude } = socketData;
    //             if (latitude && longitude) {
    //                 console.table('latitude in marker', latitude);
    //                 console.log('Updating marker position to:', latitude, longitude);
    //                 const updatedPosition = {
    //                     lat: parseFloat(latitude),
    //                     lng: parseFloat(longitude),
    //                 };
    
    //                 if (markerRef.current && mapInstanceRef.current) {
    //                     const animateMarker = (currentPosition, targetPosition, step = 0.05) => {
    //                         const lat = currentPosition.lat + (targetPosition.lat - currentPosition.lat) * step;
    //                         const lng = currentPosition.lng + (targetPosition.lng - currentPosition.lng) * step;
    
    //                         const interpolatedPosition = { lat, lng };
    //                         markerRef.current.setPosition(interpolatedPosition);
    
    //                         if (Math.abs(lat - targetPosition.lat) > 0.0001 || Math.abs(lng - targetPosition.lng) > 0.0001) {
    //                             requestAnimationFrame(() => animateMarker(interpolatedPosition, targetPosition, step));
    //                         } else {
    //                             markerRef.current.setPosition(targetPosition); // Ensure the marker reaches the final position
    //                         }
    //                     };
    
    //                     // Get current marker position
    //                     const currentMarkerPosition = {
    //                         lat: markerRef.current.getPosition().lat(),
    //                         lng: markerRef.current.getPosition().lng(),
    //                     };
    
    //                     // Animate marker to the updated position
    //                     animateMarker(currentMarkerPosition, updatedPosition);
    
    //                     //mapInstanceRef.current.panTo(updatedPosition);
    //                     console.log('Marker and map updated smoothly');
    //                 }
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error initializing/updating map:', error);
    //     }
    // }, [userDetails, socketData, isGoogleMapsLoaded]);




    // useEffect(() => {
    //     // Don't proceed if map ref or Google Maps isn't loaded
    //     if (!mapRef.current || !isGoogleMapsLoaded) return;
    
    //     try {
    //         // Wait for socket data if it's the initial load
    //         if (!mapInstanceRef.current && !socketData?.latitude) {
    //             console.log('Waiting for socket data before initializing map...');
    //             setIsLoading(true)
    //             return;
    //         }
    
    //         const getInitialPosition = () => {
    //             if (socketData?.latitude && socketData?.longitude) {
    //                 setIsLoading(fa)
    //                 return {
    //                     lat: parseFloat(socketData.latitude),
    //                     lng: parseFloat(socketData.longitude)
    //                 };
    //             }
                
    //             if (userDetails?.iyzilDetail?.coordinates) {
    //                 return {
    //                     lat: parseFloat(userDetails.iyzilDetail.coordinates.lat),
    //                     lng: parseFloat(userDetails.iyzilDetail.coordinates.lng)
    //                 };
    //             }
                
    //             // Only use default if we absolutely have to
    //             return { lat: 33.6844, lng: 73.0479 };
    //         };
    
    //         const initialPosition = getInitialPosition();
    
    //         // Initialize map only if it hasn't been initialized and we have real coordinates
    //         if (!mapInstanceRef.current) {
    //             console.log('Initializing map with coordinates:', initialPosition);
    //             mapInstanceRef.current = new window.google.maps.Map(mapRef.current, {
    //                 center: initialPosition,
    //                 zoom: 12,
    //             });
    
    //             markerRef.current = new window.google.maps.Marker({
    //                 position: initialPosition,
    //                 map: mapInstanceRef.current,
    //                 title: 'User Location',
    //             });
    //         }
    
    //         // Update marker position based on socket data
    //         if (socketData?.latitude && socketData?.longitude) {
    //             const updatedPosition = {
    //                 lat: parseFloat(socketData.latitude),
    //                 lng: parseFloat(socketData.longitude),
    //             };
    
    //             if (markerRef.current && mapInstanceRef.current) {
    //                 const animateMarker = (currentPosition, targetPosition, step = 0.05) => {
    //                     const lat = currentPosition.lat + (targetPosition.lat - currentPosition.lat) * step;
    //                     const lng = currentPosition.lng + (targetPosition.lng - currentPosition.lng) * step;
    
    //                     const interpolatedPosition = { lat, lng };
    //                     markerRef.current.setPosition(interpolatedPosition);
    
    //                     if (Math.abs(lat - targetPosition.lat) > 0.0001 || 
    //                         Math.abs(lng - targetPosition.lng) > 0.0001) {
    //                         requestAnimationFrame(() => 
    //                             animateMarker(interpolatedPosition, targetPosition, step)
    //                         );
    //                     } else {
    //                         markerRef.current.setPosition(targetPosition);
    //                     }
    //                 };
    
    //                 const currentMarkerPosition = {
    //                     lat: markerRef.current.getPosition().lat(),
    //                     lng: markerRef.current.getPosition().lng(),
    //                 };
    
    //                 animateMarker(currentMarkerPosition, updatedPosition);
    //             }
    //         }
    //         setIsLoading(false)
    //     } catch (error) {
    //         console.error('Error initializing/updating map:', error);
    //     }
    // }, [userDetails, socketData, isGoogleMapsLoaded]);
    

    return (
        <>
            {isLoading ? (
                <div style={{ textAlign: 'center', padding: '20px', fontSize: '18px' }}>
                    Please wait, map is loading...
                </div>
            ) : (
                <div
                    ref={mapRef}
                    style={{
                        width: '100%',
                        height: '100%',
                        border: '1px solid #ececec',
                    }}
                />
            )}
        </>
    );
};

export default GoogleMap;















// import React, { useEffect, useRef, useState } from 'react';
// import socketIOClient from 'socket.io-client';
// import { SOCKET_ENDPOINT } from '../config/keys.config';
// import { SOCKET_AUTHENTICATION , SOCKET_LIVELOCATION } from '../constants/socket';
// import constants from '../constants/socket';

// const GoogleMap = ({ userDetails }) => {
//     const mapRef = useRef(null);
//     const markerRef = useRef(null);
//     const mapInstanceRef = useRef(null);
//     const socketRef = useRef(null);
//     const [socketData, setSocketData] = useState(null);
//     console.log('socketData', socketData)

//     // Initialize socket connection
//     useEffect(() => {
//         console.log('Connecting to socket at:', SOCKET_ENDPOINT);
        
//         socketRef.current = socketIOClient(SOCKET_ENDPOINT, {
//             path: '/api/socket.io',
//             reconnectionAttempts: 5,
//             transports: ['websocket', 'polling'] // Explicitly specify transport methods
//         });

//         socketRef.current.on('connect', () => {
//             console.log('Socket connected successfully');
            
//             socketRef.current.emit(SOCKET_AUTHENTICATION, {
//                 token: constants.socketToken
//             });
//             console.log('Authentication event emitted');
//         });

        
//         socketRef.current.on('connect_error', (error) => {
//             console.error('Socket connection error:', error);
//         });
        
//         return () => {
//             console.log('Cleaning up socket connection');
//             if (socketRef.current) {
//                 socketRef.current.disconnect();
//             }
//         };
//     }, [socketData]);

   
//  useEffect(()=>{
//     socketRef.current.on(SOCKET_LIVELOCATION, (data) => {
//         console.log('Received live location data:', data);
//         setSocketData(data);
//     });
//  },[socketData])

   
//     useEffect(() => {
//         if (!mapRef.current) return;

//         const defaultCoordinates = { lat: 33.6844, lng: 73.0479 };
//         const initialCoordinates = userDetails?.iyzilDetail?.coordinates
//             ? {
//                 lat: parseFloat(userDetails.iyzilDetail.coordinates.lat),
//                 lng: parseFloat(userDetails.iyzilDetail.coordinates.lng),
//             }
//             : defaultCoordinates;

//         // Initialize map only if it hasn't been initialized
//         if (!mapInstanceRef.current) {
//             console.log('Initializing map with coordinates:', initialCoordinates);
//             mapInstanceRef.current = new window.google.maps.Map(mapRef.current, {
//                 center: initialCoordinates,
//                 zoom: 12,
//             });

//             markerRef.current = new window.google.maps.Marker({
//                 position: initialCoordinates,
//                 map: mapInstanceRef.current,
//                 title: 'User Location',
//             });
//         }

//         // Update marker position if on basis socket data
//         if (socketData) {
//             const { latitude, longitude } = socketData;
//             if (latitude && longitude) {
//                 console.log('Updating marker position to:', latitude, longitude);
//                 const updatedPosition = {
//                     lat: parseFloat(latitude),
//                     lng: parseFloat(longitude)
//                 };
                
//                 if (markerRef.current && mapInstanceRef.current) {
//                     markerRef.current.setPosition(updatedPosition);
//                     mapInstanceRef.current.panTo(updatedPosition);
//                     console.log('Marker and map updated successfully');
//                 }
//             }
//         }
//     }, [userDetails, socketData]);

//     return (
//         <div
//             ref={mapRef}
//             style={{
//                 width: '100%',
//                 height: '100%',
//                 border: '1px solid #ececec',
//             }}
//         />
//     );
// };

// export default GoogleMap;


