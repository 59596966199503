// import React, { useState, useEffect, useRef } from 'react';
// import { database } from './firebase';
// import { ref, onValue, push, set } from "firebase/database";
// import './chat.css';
// import ChatIcon from './assets/images/chat.svg';
// import userDummyIcon from './assets/images/dummy.svg';

// const Chat = ({ userDetails }) => {
//   const [messages, setMessages] = useState(null);
//   const [newMessage, setNewMessage] = useState('');
//   const [isInitialized, setIsInitialized] = useState(false);
//   const currentUser = "Agent";
//   const messagesEndRef = useRef(null);
//   const contactNumber = userDetails?.contact;
//   console.log('messages', messages)
//   // Changed the Firebase reference path to include contact number in the structure
//   const messagesRef = contactNumber 
//     ? ref(database, `messages/contacts/${contactNumber}/chats`) 
//     : null;

//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   };

//   useEffect(() => {
//     // Reset states when contact changes
//     setIsInitialized(false);
//     setMessages(null);

//     // Only subscribe if we have a valid contact number and reference
//     if (!contactNumber || !messagesRef) {
//       setIsInitialized(true);
//       setMessages([]);
//       return;
//     }

//     const unsubscribe = onValue(messagesRef, (snapshot) => {
//       const data = snapshot.val();
//       const messagesList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
//       setMessages([messagesList]);
//       setIsInitialized(true);
//     }, (error) => {
//       console.error("Error loading messages:", error);
//       setIsInitialized(true);
//       setMessages([]);
//     });

//     return () => unsubscribe();
//   }, [contactNumber]);

//   useEffect(() => {
//     if (isInitialized && messages?.length > 0) {
//       scrollToBottom();
//     }
//   }, [messages, isInitialized]);

//   const handleSendMessage = async () => {
//     if (!messagesRef || newMessage.trim() === '') return;

//     const newMessageRef = push(messagesRef);
//     try {
//       await set(newMessageRef, {
//         message: newMessage,
//         name: currentUser,
//         timestamp: Date.now()
//       });
//       setNewMessage('');
//     } catch (error) {
//       console.error("Error writing new message to Firebase Database", error);
//     }
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter') {
//       handleSendMessage();
//     }
//   };

//   const formatTime = (timestamp) => {
//     const date = new Date(timestamp);
//     if (isNaN(date.getTime())) return;
//     return date.toLocaleTimeString('en-US', {
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//     });
//   };

//   if (!isInitialized || messages === null) {
//     return (
//       <div className="chat-wrapper">
//         <div className="messages-container">
//           <div className="chat-loading">Loading messages...</div>
//         </div>
//       </div>
//     );
//   }

//   return (
//     <div className="chat-wrapper">
//       <div className="messages-container">
//         {messages.length === 0 ? (
//           <div className="no-messages">
//             <p>No messages yet. Start a conversation!</p>
//           </div>
//         ) : (
//           messages.map((message) => (
//             <div
//               key={message.id}
//               className={`message-row ${message.name === currentUser ? "sent" : "received"}`}
//             >
//               <img
//                 alt={message.name}
//                 src={userDummyIcon}
//               />
//               <div className="message-div">
//                 <div className={`message-bubble ${message.name === currentUser ? "sent" : "received"}`}>
//                   <p className="message-text"><strong>{message.name}:</strong> {message.message}</p>
//                 </div>
//                 <span className="message-time">{formatTime(message.timestamp)}</span>
//               </div>
//             </div>
//           ))
//         )}
//         <div ref={messagesEndRef} />
//       </div>
//       <div className="input-container">
//         <input
//           type="text"
//           value={newMessage}
//           onChange={(e) => setNewMessage(e.target.value)}
//           onKeyPress={handleKeyPress}
//           className="message-input"
//           placeholder="Send message"
//         />
//         <img
//           onClick={handleSendMessage}
//           src={ChatIcon}
//           alt="Send"
//           className="send-icon"
//         />
//       </div>
//     </div>
//   );
// };

// export default Chat;









// //BEST WORKING CODE WITH PROPER HANDLING OF NOT SHOWING MESSAGES ON PAGE LOAD IF USER HAS NO CHAT HISTORY 
// import React, { useState, useEffect, useRef } from 'react';
// import { database } from './firebase';
// import { ref, onValue, push, set } from "firebase/database";
// import './chat.css';
// import ChatIcon from './assets/images/chat.svg';
// import userDummyIcon from './assets/images/dummy.svg';

// const Chat = ({ userDetails }) => {
//   const [messages, setMessages] = useState([]);
//   const [newMessage, setNewMessage] = useState('');
//   const [isLoading, setIsLoading] = useState(true);
//   const currentUser = "Agent";
//   const messagesEndRef = useRef(null);
//   const prevMessagesRef = useRef([]);
//   const contactNumber = userDetails?.contact;
//   const messagesRef = ref(database, `/groups/${contactNumber}`);
//  console.log('messages', messages)
//   const scrollToBottom = () => {
//     messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
//   };

//   useEffect(() => {
//     let isSubscribed = true;

//     const unsubscribe = onValue(messagesRef, (snapshot) => {
//       if (isSubscribed) {
//         const data = snapshot.val();
//         const messagesList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
//         setMessages(messagesList);
//         setIsLoading(false);  // Set loading to false once we get the initial data
//       }
//     }, (error) => {
//       console.error("Error loading messages:", error);
//       if (isSubscribed) {
//         setIsLoading(false);
//       }
//     });

//     // Cleanup subscription
//     return () => {
//       isSubscribed = false;
//       unsubscribe();
//     };
//   }, [messagesRef]);

//   useEffect(() => {
//     if (!isLoading && prevMessagesRef.current.length !== messages.length) {
//       scrollToBottom();
//     }
//     prevMessagesRef.current = messages;
//   }, [messages, isLoading]);

//   const handleSendMessage = async () => {
//     if (newMessage.trim() === '') return;

//     const newMessageRef = push(messagesRef);
//     try {
//       await set(newMessageRef, {
//         message: newMessage,
//         name: currentUser,
//         timestamp: Date.now()
//       });
//       setNewMessage('');
//     } catch (error) {
//       console.error("Error writing new message to Firebase Database", error);
//     }
//   };

//   const handleKeyPress = (event) => {
//     if (event.key === 'Enter') {
//       handleSendMessage();
//     }
//   }

//   const formatTime = (timestamp) => {
//     const date = new Date(timestamp);
//     if (isNaN(date.getTime())) {
//       return;
//     }
//     return date.toLocaleTimeString('en-US', {
//       hour: '2-digit',
//       minute: '2-digit',
//       hour12: true,
//     });
//   };

//   return (
//     <div className="chat-wrapper">
//       <div className="messages-container">
//         {isLoading ? (
//           <div className="chat-loading">Loading messages...</div>
//         ) : messages.length === 0 ? (
//           <div className="no-messages">
//             <p>No messages yet. Start a conversation!</p>
//           </div>
//         ) : (
//           messages.map((message) => (
//             <div
//               key={message.id}
//               className={`message-row ${message.name === currentUser ? "sent" : "received"}`}
//             >
//               <img
//                 alt={message.name}
//                 src={userDummyIcon}
//               />
//               <div className='message-div'>
//                 <div className={`message-bubble ${message.name === currentUser ? "sent" : "received"}`}>
//                   <p className="message-text"><strong>{message.name}:</strong> {message.message}</p>
//                 </div>
//                 <span className="message-time">{formatTime(message.timestamp)}</span>
//               </div>
//             </div>
//           ))
//         )}
//         <div ref={messagesEndRef} />
//       </div>
//       <div className="input-container">
//         <input
//           type="text"
//           value={newMessage}
//           onChange={(e) => setNewMessage(e.target.value)}
//           onKeyPress={handleKeyPress}
//           className="message-input"
//           placeholder="Send message"
//         />
//         <img
//           onClick={handleSendMessage}
//           src={ChatIcon}
//           alt="Send"
//           className="send-icon"
//         />
//       </div>
//     </div>
//   );
// };

// export default Chat;

































//CHANGE THE PATH OF THE DATABASE AND MAKE PATH SPECIFIC TO THE CONTACT INSTEAD OF GRO
//BEST CODE BUT HERE CHAT IS SHOWN IN FIRST RELOAD WHICH I HANDLED IN ABOVE UNCOMMENDTED CODE OK THIS CODE IS WRITTEN BY MOHSIN BHAI
import React, { useState, useEffect, useRef } from 'react';
import { database } from './firebase';
//import { ref, onValue, push, set } from "firebase/database";
import './chat.css';
import ChatIcon from './assets/images/chat.svg';
import userDummyIcon from './assets/images/dummy.svg';
import axios from 'axios';
import { ref, onValue, push, set, remove } from "firebase/database";
import { BACKEND_URL } from './config/keys.config';

const Chat = ({ userDetails, IsUserSafe }) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [data, setData] = useState(null)
  const currentUser = "Agent";
  const messagesEndRef = useRef(null);
  const prevMessagesRef = useRef([]);
  const [isLoading, setIsLoading] = useState(false)
  const [snapshot, setSnapshot] = useState()
  //const [isLoading, setIsLoading] = useState(true)

  const contactNumber = userDetails?.contact;
  const userId = userDetails?._id;


  const messagesRef = ref(database, `/groups/${contactNumber}`);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const sendUserIdToBackend = async () => {
    try {
      const res = await axios.get(`${BACKEND_URL}/api/v1/user/sendNotification?userId=${userId}`)
    } catch (error) {
      console.log('error', error)
    }

  }
  useEffect(() => {
    if (!contactNumber) return;

    const messagesRef = ref(database, `/groups/${contactNumber}`);
    setIsLoading(true); // Start loading
    const unsubscribe = onValue(messagesRef, (snapshot) => {
      setSnapshot(snapshot.val())
      const data = snapshot.val();
      setData(data);

      const messagesList = data
        ? Object.keys(data).map(key => ({ id: key, ...data[key] }))
        : [];

      setMessages(messagesList);
      setIsLoading(false); // End loading
    });

    return () => unsubscribe();
  }, [contactNumber]);


  useEffect(() => {
  
        if (IsUserSafe) {
            remove(messagesRef)
                .then(() => console.log("Chat messages deleted successfully!"))
                .catch((error) => console.error("Error removing messages:", error));
        }
    }, [IsUserSafe]);

  // useEffect(() => {
  // //  setIsLoading(true)
  //   const unsubscribe = onValue(messagesRef, (snapshot) => {
  //     const data = snapshot.val();
  //    setData(data)
  //     const messagesList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
  //     setMessages(messagesList);
  //    // setIsLoading(false)
  //   });

  //   return () => unsubscribe();
  // }, [messagesRef]);

  useEffect(() => {
    if (prevMessagesRef.current.length !== messages.length) {
      scrollToBottom();
    }
    prevMessagesRef.current = messages;
  }, [messages]);

  const handleSendMessage = async () => {
    if (newMessage.trim() === '') return;

    const newMessageRef = push(messagesRef);
    try {
      await set(newMessageRef, {
        message: newMessage,
        name: currentUser,
        timestamp: Date.now()
      });
      setNewMessage('');


    } catch (error) {
      console.error("Error writing new message to Firebase Database", error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
      sendUserIdToBackend();
    }
  }


  const formatTime = (timestamp) => {
    const date = new Date(timestamp);
    if (isNaN(date.getTime())) {
      return;
    }
    return date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      hour12: true,
    });
  };

  return (
    <div className="chat-wrapper">
      <div className="messages-container">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`message-row ${message.name === currentUser ? "sent" : "received"}`}
          >
            <img
              alt={message.name}
              src={userDummyIcon}
            />
            <div className='message-div'>
              <div className={`message-bubble ${message.name === currentUser ? "sent" : "received"}`}>
                <p className="message-text"><strong>{message.name}:</strong> {message.message}</p>
              </div>
              <span className="message-time">{formatTime(message.timestamp)}</span>
            </div>
          </div>
        ))
        }
        <div ref={messagesEndRef} />
      </div>
      {/* <div className="messages-container">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`message-row ${message.name === currentUser ? "sent" : "received"}`}
          >
            <div className="message-content">
              <div className="message-header">
                <span className="message-sender">{message.name}</span>
              </div>
              <div className={`message-bubble ${message.name === currentUser ? "sent" : "received"}`}>
                <p className="message-text">{message.message}</p>
                <span className="message-time">{formatTime(message.timestamp)}</span>
              </div>
            </div>
            <img
              alt={message.name}
              src={userDummyIcon}
            />
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div> */}
      <div className="input-container">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          className="message-input"
          placeholder="Send message"
        />
        <img
          onClick={handleSendMessage}
          src={ChatIcon}
          alt="Send"
          className="send-icon"
        />
      </div>
    </div>
  );
};

export default Chat;

