//BEST WORKING CODE
import React, { useState, useEffect, useCallback } from 'react';
import VideoCall from "./VideoCall";
import Chat from "./Chat";
import '../src/App.css'
import Header from './header/Header';
import CustomerPanel from './panel/CustomerPanel';
import GoogleMap from './map/GoogleMap.jsx';
import { useLocation, useNavigate } from 'react-router-dom';
import { useClient, useMicrophoneAndCameraTracks, config } from './settings.js';
import socketIOClient from 'socket.io-client'
import constants from './constants/socket.js';
import { AGORA_USERNAME, AGORA_PASSWORD, AGORA_API_KEY } from './config/keys.config.js';
import { SOCKET_AUTHENTICATION } from './constants/socket.js';
import { BACKEND_URL } from './config/keys.config.js';
import { set } from 'firebase/database';

function App() {
  const [inCall, setInCall] = useState(true);
  const [userDetails, setUserDetails] = useState(null);
  const [safeUserAlert, setSafeUserAlert] = useState(null)
  const [userJoinAgain, setUserJoinAgain] = useState(false)
  const [apiToken, setApiToken] = useState(null);
  const [users, setUsers] = useState([]);
  const [start, setStart] = useState(false);
  //const [origin, setOrigin] = useState("")
  const [error, setError] = useState(null);
  const [apiError, setApiError] = useState(null)
  //const [isFullScreen, setIsFullScreen] = useState(false);
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [updatedLocation, setUpdatedLocation] = useState(null)
  const [IsUserSafe, setIsUserSafe] = useState(false)
  const [socket, setSocket] = useState(null)
  console.log('socket in app.js', socket)
  console.log('isUserSafe', IsUserSafe)
  const client = useClient();
  const { ready, tracks } = useMicrophoneAndCameraTracks();
  const channelNameNew = userDetails?.contact;
  const channelName = channelNameNew;
  const queryParams = new URLSearchParams(window.location.search); // Get the query string
  const alarmId = queryParams.get('alarmId');

  //  useEffect(() => {
  //   const userLeft = localStorage.getItem('userLeftFullScreen');
  //   if (userLeft === 'true') {
  //     setIsFullScreen(false);
  //     // Clear the flag after reading it
  //     localStorage.removeItem('userLeftFullScreen');
  //   }
  // }, []);


  //blocking camera


  const connectSocketToListenAlarmSafe=()=>{
    try {
      const newSocket = socketIOClient(BACKEND_URL, {
        path: '/api/socket.io',
        reconnectionAttempts: 5,
        transports: ['websocket'],
        query: { token: constants.socketToken.token },
     })


     newSocket.on('connect', () => {
                     console.log('Socket IN APP.JS connected successfully');
                     setSocket(newSocket)
                     setSocket(true)
                    
                     newSocket.emit(SOCKET_AUTHENTICATION, {
                         token: "89a3da5810da0c14867a1718b52248feb1a19ea83a4e17681a85ceaea15ee184"
                     });
                     console.log('Authentication event emitted');
                    
                 });

                 newSocket.on(`userMarkAsSafe:${alarmId}`,(data)=>{
                  const {isSafe} = data;
                  setIsUserSafe(isSafe)
                 })

                 newSocket.on('disconnect',()=>{
                  console.log('i am disconnecting socket')
                  return ()=>{
                      socket.disconnect()
                      //newSocket.off(`${SOCKET_LIVELOCATION}`)
                  }
              })

    
    } catch (error) {
      
    }
  }
  useEffect(()=>{
connectSocketToListenAlarmSafe()
  },[])

//   useEffect(()=>{
// if(socket){
//  socket.on(`userMarkAsSafe:${alarmId}`,(data)=>{
//   const {isSafe} = data;
//   setIsUserSafe(isSafe)
//  })
// }
//   },[alarmId,socket])
  const clearSearchParams = () => {
    const url = new URL(window.location.href);
    url.search = '';
    window.history.replaceState({}, document.title, url.toString());
  };

  const handleDisconnect = (message) => {
    setIsFullScreen(true);
    //setError(message || "Remote user disconnected");
    setInCall(false);
    //setIsUserSafe(true)

    //clearSearchParams();
    //setReload(window.location.reload())

  };

  const handleConnect = useCallback(() => {
    
    console.log("handleConnect called");
    setIsFullScreen(false);

  }, []);


  //function to handle parameters and id related errors
  const handleValidationErrors = (msg) => {
    setError(msg || "Parameters not found!")
    setInCall(false)
    clearSearchParams()
  }

  //pseudocode of the problem of the same function
  //this function should call when user add pin manually after clicking on safe button ok
  const handleSafeUser = (msg) => {
  
    //setSafeUserAlert(msg)
    setInCall(false)
    clearSearchParams()
    // setIsUserSafe(true)
  }

  const getAlarmIdFromQuery = () => {
    const params = new URLSearchParams(window.location.search);
    return params.get("alarmId");
  };

  useEffect(() => {
    if (!window.location.search) {
      handleValidationErrors("Session Expired: No parameters found in the URL.");
      return;
    }

    const alarmId = getAlarmIdFromQuery();
    if (alarmId) {
      setInCall(true);
      fetchUserDetails(alarmId);
    } else {
      handleValidationErrors("Alarm ID not found in query parameters");
    }
  }, []);


  useEffect(() => {
    let init = async () => {
      if (!channelName) {
        console.error("Invalid channel name");
        return;
      }

      client.on("user-published", async (user, mediaType) => {
       
        await client.subscribe(user, mediaType);
        if (mediaType === "video") {
          setUsers((prevUsers) => [...prevUsers, user]);
          setIsFullScreen(false);
        }
        if (mediaType === "audio") {
          user.audioTrack.play();
        }


      });

      client.on("user-unpublished", (user, mediaType) => {
        
        if (mediaType === "audio") {
          if (user.audioTrack) user.audioTrack.stop();
        }
        if (mediaType === "video") {

          setUsers((prevUsers) =>
            prevUsers.filter((User) => User.uid !== user.uid)
          );
        }
      });

      client.on("user-left", (user) => {
        
        setUsers((prevUsers) =>
          prevUsers.filter((User) => User.uid !== user.uid)
        ); handleDisconnect("Remote user disconnected.");
      });

      try {

        let tok = ""
        const uid = 12345;
        const fetchToken = async () => {
          const encodedChannelName = encodeURIComponent(channelName);
          console.log("___________________>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", encodedChannelName);
          const apiUrl = `${BACKEND_URL}/api/v1/agora/Token?channelName=${encodedChannelName}&uid=${uid}`;
          console.log("backendINApp.js", BACKEND_URL)
          console.log("apiUrl ", apiUrl)
          const username = `${AGORA_USERNAME}`;
          const password = `${AGORA_PASSWORD}`;
          console.log('agoraUsername', AGORA_USERNAME)

          try {
            const response = await fetch(apiUrl, {
              method: "GET",
              headers: {
                Authorization: `Basic ${btoa(`${username}:${password}`)}`,
                "Content-Type": "application/json",
              },
            });

            if (response) {
              const data = await response.json();
              console.log('data from api')
              tok = data.token;
              setApiToken(data.token);
            } else {
              console.error("Failed to fetch token:", response.statusText);
            }
          } catch (error) {
            console.error("Error fetching token:", error);
          }
        }

        await fetchToken();
      
        // Use the extracted channel name
        await client.join(`${AGORA_API_KEY}`, channelName, tok, uid);
        console.log('AgoraApiKey', AGORA_API_KEY)

        // Publish tracks after successfully joining
        if (tracks) {
          await client.publish([tracks[0]]);
          tracks[1].setEnabled(false);
          setStart(true);
        }
      } catch (error) {
        console.error("Error joining channel:", error);
      }
    };

    // Only initialize if client, tracks are ready
    if (ready && tracks) {
      init();
    }

    return () => {
      client.removeAllListeners();
    };

  }, [channelName, client, ready, tracks, isFullScreen, setIsFullScreen, setUsers]);

const handleLocationUpdate=(updatedLocation)=>{
setUpdatedLocation({
  lat: updatedLocation.latitude,
  lng: updatedLocation.longitude,
})
}

  const fetchUserDetails = async (alarmId) => {
    try {
      const response = await fetch(
        `${BACKEND_URL}/api/v1/user/getUserForDashboard?alarmId=${alarmId}`
      );
   // const resData = await response.json()
    const status = response.status;
    if(status === 404){
     // const message = resData.message;
      setSafeUserAlert('Alarm is not valid')
      //setApiError(status)
    }
      if (!response.ok) {
        throw new Error(`API Error: ${response.status}`);
      }
      const data = await response.json();
      const newdata = data.data;
      // const origin = newdata.alarmOrigin;
      // setOrigin(origin)
      // if(origin === 'Health Alarm'){
      //   setIsFullScreen(true)
      // }
      // console.log('origin in app.js', origin)
      setUserDetails(newdata);
     

    } catch (err) {

      handleDisconnect(err.message);
    }
  };

  if (error || safeUserAlert) {
    return (
      <div className="session-expired">
        <h1>{error || safeUserAlert}</h1>
      </div>
    );
  }



  return (
    <div className="App">
      <Header />
      <div className="customer-panel-container">
        <CustomerPanel userDetails={userDetails} updatedLocation={updatedLocation}  />
      </div>
      <div className="main-content">
        <div className={`right-section ${isFullScreen ? 'fullscreen' : ''}`}>
          <GoogleMap userDetails={userDetails}  onLocationUpdate={handleLocationUpdate} />
        </div>
        {!isFullScreen && (
          <div className="left-section">
            <div className="video-container">
              <VideoCall
                setInCall={setInCall}
                userDetails={userDetails}
                onUserDisconnect={handleDisconnect}
                onUserSafe={handleSafeUser}
                isFullScreen={isFullScreen}
                setIsFullScreen={setIsFullScreen}
                handleConnect={handleConnect}
                users={users}
                setUsers={setUsers}
                setStart={setStart}
                start={start}
                tracks={tracks}
                ready={ready}

              />
            </div>
            <div className="chat-container">
              <Chat userDetails={userDetails} IsUserSafe={IsUserSafe} />
            </div>
          </div>
        )}

      </div>
    </div>
  );
}

export default App;


















// import React, { useState, useEffect } from 'react';
// import VideoCall from "./VideoCall";
// import Chat from "./Chat";
// import '../src/App.css'
// import Header from './header/Header';
// import CustomerPanel from './panel/CustomerPanel';
// import GoogleMap from './map/GoogleMap';

// function App() {
//   const [inCall, setInCall] = useState(true);
//   const [userDetails, setUserDetails] = useState(null);
//   const [error, setError] = useState(null);
//   const [isFullScreen, setIsFullScreen] = useState(false)
//   console.log('isFullScreen*******', isFullScreen)


//   const clearSearchParams = () => {
//     const url = new URL(window.location.href);
//     url.search = '';
//     window.history.replaceState({}, document.title, url.toString());
//   };

//   const handleDisconnect = (message) => {
//     setError(message || "Remote user disconnected");
//     setInCall(false);
//     setIsFullScreen(true)
//     clearSearchParams();
//   };

//   const getAlarmIdFromQuery = () => {
//     const params = new URLSearchParams(window.location.search);
//     return params.get("alarmId");
//   };

//   useEffect(() => {

//     if (!window.location.search) {
//       handleDisconnect("Session Expired: No parameters found in the URL.");
//       return;
//     }

//     const alarmId = getAlarmIdFromQuery();
//     if (alarmId) {
//       fetchUserDetails(alarmId);
//     } else {
//       handleDisconnect("Alarm ID not found in query parameters");
//     }
//   }, []);

//   const fetchUserDetails = async (alarmId) => {
//     try {
//       const response = await fetch(
//         `https://stagdashboard.iylus.com/api/v1/user/getUserForDashboard?alarmId=${alarmId}`
//       );
//       if (!response.ok) {
//         throw new Error(`API Error: ${response.status}`);
//       }
//       const data = await response.json();
//       const newdata = data.data;
//       console.log("???????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", newdata);

//       setUserDetails(newdata);

//     } catch (err) {
//       handleDisconnect(err.message);
//     }
//   };

//   if (error) {
//     return (
//       <div className="session-expired">
//         <h1>{error}</h1>
//       </div>
//     );
//   }

//   return (
//     <div className="App">
//       <Header />
//       <div className="customer-panel-container">
//         <CustomerPanel userDetails={userDetails} />
//       </div>
//       <div className="main-content">
//         <div className="right-section">
//           <GoogleMap userDetails={userDetails} />
//         </div>
//         <div className="left-section">
//           <div className="video-container">
//             <VideoCall setInCall={setInCall} userDetails={userDetails} onUserDisconnect={handleDisconnect} />
//           </div>
//           <div className="chat-container">
//             <Chat userDetails={userDetails} />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default App;










// //BEST WORKING CODE
// import React, { useState, useEffect, useCallback } from 'react';
// import VideoCall from "./VideoCall";
// import Chat from "./Chat";
// import '../src/App.css'
// import Header from './header/Header';
// import CustomerPanel from './panel/CustomerPanel';
// import GoogleMap from './map/GoogleMap.jsx';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { useClient, useMicrophoneAndCameraTracks, config } from './settings.js';
// import { AGORA_USERNAME, AGORA_PASSWORD, AGORA_API_KEY } from './config/keys.config.js';

// function App() {
//   const [inCall, setInCall] = useState(true);
//   const [userDetails, setUserDetails] = useState(null);
//   const [safeUserAlert, setSafeUserAlert] = useState(null)
//   const [userJoinAgain, setUserJoinAgain] = useState(false)
//   const [apiToken, setApiToken] = useState(null);
//   const [users, setUsers] = useState([]);
//   const [start, setStart] = useState(false);

//   const [error, setError] = useState(null);
//   const [apiError, setApiError] = useState(null)
//   //const [isFullScreen, setIsFullScreen] = useState(false);
//   const [isFullScreen, setIsFullScreen] = useState(false)
//   const [updatedLocation, setUpdatedLocation] = useState(null)
//   const client = useClient();
//   const { ready, tracks } = useMicrophoneAndCameraTracks();
//   const channelNameNew = userDetails?.contact;
//   const channelName = channelNameNew;

//   //  useEffect(() => {
//   //   const userLeft = localStorage.getItem('userLeftFullScreen');
//   //   if (userLeft === 'true') {
//   //     setIsFullScreen(false);
//   //     // Clear the flag after reading it
//   //     localStorage.removeItem('userLeftFullScreen');
//   //   }
//   // }, []);


//   //blocking camera


//   const clearSearchParams = () => {
//     const url = new URL(window.location.href);
//     url.search = '';
//     window.history.replaceState({}, document.title, url.toString());
//   };

//   const handleDisconnect = (message) => {
//     setIsFullScreen(true);
//     //setError(message || "Remote user disconnected");
//     setInCall(false);

//     //clearSearchParams();
//     //setReload(window.location.reload())

//   };

//   const handleConnect = useCallback(() => {
//     console.log("handleConnect called");
//     setIsFullScreen(false);
//   }, []);


//   //function to handle parameters and id related errors
//   const handleValidationErrors = (msg) => {
//     setError(msg || "Parameters not found!")
//     setInCall(false)
//     clearSearchParams()
//   }

//   //pseudocode of the problem of the same function
//   //this function should call when user add pin manually after clicking on safe button ok
//   const handleSafeUser = (msg) => {
//     //setSafeUserAlert(msg)
//     setInCall(false)
//     clearSearchParams()
//   }

//   const getAlarmIdFromQuery = () => {
//     const params = new URLSearchParams(window.location.search);
//     return params.get("alarmId");
//   };

//   useEffect(() => {
//     if (!window.location.search) {
//       handleValidationErrors("Session Expired: No parameters found in the URL.");
//       return;
//     }

//     const alarmId = getAlarmIdFromQuery();
//     if (alarmId) {
//       setInCall(true);
//       fetchUserDetails(alarmId);
//     } else {
//       handleValidationErrors("Alarm ID not found in query parameters");
//     }
//   }, []);


//   useEffect(() => {
//     let init = async () => {
//       if (!channelName) {
//         console.error("Invalid channel name");
//         return;
//       }

//       client.on("user-published", async (user, mediaType) => {
//         await client.subscribe(user, mediaType);
//         if (mediaType === "video") {
//           setUsers((prevUsers) => [...prevUsers, user]);
//           setIsFullScreen(false);
//         }
//         if (mediaType === "audio") {
//           user.audioTrack.play();
//         }


//       });

//       client.on("user-unpublished", (user, mediaType) => {
//         if (mediaType === "audio") {
//           if (user.audioTrack) user.audioTrack.stop();
//         }
//         if (mediaType === "video") {

//           setUsers((prevUsers) =>
//             prevUsers.filter((User) => User.uid !== user.uid)
//           );
//         }
//       });

//       client.on("user-left", (user) => {
//         setUsers((prevUsers) =>
//           prevUsers.filter((User) => User.uid !== user.uid)
//         ); handleDisconnect("Remote user disconnected.");
//       });

//       try {

//         let tok = ""
//         const uid = 12345;
//         const fetchToken = async () => {
//           const encodedChannelName = encodeURIComponent(channelName);
//           console.log("___________________>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", encodedChannelName);
//           const apiUrl = `https://stagdashboard.iylus.com/api/v1/agora/Token?channelName=${encodedChannelName}&uid=${uid}`;
//           console.log("apiUrl ", apiUrl)
//           const username = `${AGORA_USERNAME}`;
//           const password = `${AGORA_PASSWORD}`;

//           try {
//             const response = await fetch(apiUrl, {
//               method: "GET",
//               headers: {
//                 Authorization: `Basic ${btoa(`${username}:${password}`)}`,
//                 "Content-Type": "application/json",
//               },
//             });

//             if (response) {
//               const data = await response.json();
//               console.log('data from api')
//               tok = data.token;
//               setApiToken(data.token);
//             } else {
//               console.error("Failed to fetch token:", response.statusText);
//             }
//           } catch (error) {
//             console.error("Error fetching token:", error);
//           }
//         }

//         await fetchToken();
      
//         // Use the extracted channel name
//         await client.join(`${AGORA_API_KEY}`, channelName, tok, uid);
        

//         // Publish tracks after successfully joining
//         if (tracks) {
//           await client.publish([tracks[0]]);
//           tracks[1].setEnabled(false);
//           setStart(true);
//         }
//       } catch (error) {
//         console.error("Error joining channel:", error);
//       }
//     };

//     // Only initialize if client, tracks are ready
//     if (ready && tracks) {
//       init();
//     }

//     return () => {
//       client.removeAllListeners();
//     };

//   }, [channelName, client, ready, tracks, isFullScreen, setIsFullScreen, setUsers]);

// const handleLocationUpdate=(updatedLocation)=>{
// setUpdatedLocation({
//   lat: updatedLocation.latitude,
//   lng: updatedLocation.longitude,
// })
// }

//   const fetchUserDetails = async (alarmId) => {
//     try {
//       const response = await fetch(
//         `https://stagdashboard.iylus.com/api/v1/user/getUserForDashboard?alarmId=${alarmId}`
//       );
//    // const resData = await response.json()
//     const status = response.status;
//     if(status === 404){
//      // const message = resData.message;
//       setSafeUserAlert('Alarm is not valid')
//       //setApiError(status)
//     }
//       if (!response.ok) {
//         throw new Error(`API Error: ${response.status}`);
//       }
//       const data = await response.json();
//       const newdata = data.data;
//       setUserDetails(newdata);
     

//     } catch (err) {

//       handleDisconnect(err.message);
//     }
//   };

//   if (error || safeUserAlert) {
//     return (
//       <div className="session-expired">
//         <h1>{error || safeUserAlert}</h1>
//       </div>
//     );
//   }



//   return (
//     <div className="App">
//       <Header />
//       <div className="customer-panel-container">
//         <CustomerPanel userDetails={userDetails} updatedLocation={updatedLocation}  />
//       </div>
//       <div className="main-content">
//         <div className={`right-section ${isFullScreen ? 'fullscreen' : ''}`}>
//           <GoogleMap userDetails={userDetails}  onLocationUpdate={handleLocationUpdate} />
//         </div>
//         {!isFullScreen && (
//           <div className="left-section">
//             <div className="video-container">
//               <VideoCall
//                 setInCall={setInCall}
//                 userDetails={userDetails}
//                 onUserDisconnect={handleDisconnect}
//                 onUserSafe={handleSafeUser}
//                 isFullScreen={isFullScreen}
//                 setIsFullScreen={setIsFullScreen}
//                 handleConnect={handleConnect}
//                 users={users}
//                 setUsers={setUsers}
//                 setStart={setStart}
//                 start={start}
//                 tracks={tracks}
//                 ready={ready}

//               />
//             </div>
//             <div className="chat-container">
//               <Chat userDetails={userDetails} />
//             </div>
//           </div>
//         )}

//       </div>
//     </div>
//   );
// }

// export default App;


















// // import React, { useState, useEffect } from 'react';
// // import VideoCall from "./VideoCall";
// // import Chat from "./Chat";
// // import '../src/App.css'
// // import Header from './header/Header';
// // import CustomerPanel from './panel/CustomerPanel';
// // import GoogleMap from './map/GoogleMap';

// // function App() {
// //   const [inCall, setInCall] = useState(true);
// //   const [userDetails, setUserDetails] = useState(null);
// //   const [error, setError] = useState(null);
// //   const [isFullScreen, setIsFullScreen] = useState(false)
// //   console.log('isFullScreen*******', isFullScreen)


// //   const clearSearchParams = () => {
// //     const url = new URL(window.location.href);
// //     url.search = '';
// //     window.history.replaceState({}, document.title, url.toString());
// //   };

// //   const handleDisconnect = (message) => {
// //     setError(message || "Remote user disconnected");
// //     setInCall(false);
// //     setIsFullScreen(true)
// //     clearSearchParams();
// //   };

// //   const getAlarmIdFromQuery = () => {
// //     const params = new URLSearchParams(window.location.search);
// //     return params.get("alarmId");
// //   };

// //   useEffect(() => {

// //     if (!window.location.search) {
// //       handleDisconnect("Session Expired: No parameters found in the URL.");
// //       return;
// //     }

// //     const alarmId = getAlarmIdFromQuery();
// //     if (alarmId) {
// //       fetchUserDetails(alarmId);
// //     } else {
// //       handleDisconnect("Alarm ID not found in query parameters");
// //     }
// //   }, []);

// //   const fetchUserDetails = async (alarmId) => {
// //     try {
// //       const response = await fetch(
// //         `https://stagdashboard.iylus.com/api/v1/user/getUserForDashboard?alarmId=${alarmId}`
// //       );
// //       if (!response.ok) {
// //         throw new Error(`API Error: ${response.status}`);
// //       }
// //       const data = await response.json();
// //       const newdata = data.data;
// //       console.log("???????????????????????????????????>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>", newdata);

// //       setUserDetails(newdata);

// //     } catch (err) {
// //       handleDisconnect(err.message);
// //     }
// //   };

// //   if (error) {
// //     return (
// //       <div className="session-expired">
// //         <h1>{error}</h1>
// //       </div>
// //     );
// //   }

// //   return (
// //     <div className="App">
// //       <Header />
// //       <div className="customer-panel-container">
// //         <CustomerPanel userDetails={userDetails} />
// //       </div>
// //       <div className="main-content">
// //         <div className="right-section">
// //           <GoogleMap userDetails={userDetails} />
// //         </div>
// //         <div className="left-section">
// //           <div className="video-container">
// //             <VideoCall setInCall={setInCall} userDetails={userDetails} onUserDisconnect={handleDisconnect} />
// //           </div>
// //           <div className="chat-container">
// //             <Chat userDetails={userDetails} />
// //           </div>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // }

// // export default App;
