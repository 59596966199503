// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Progressbar_progressBarContainer__7T9xs {\n    width: 100%;\n    height: 10px;\n    background-color: #e0e0e0;\n    border-radius: 5px;\n    overflow: hidden;\n    margin-top: 5px;\n  }\n  \n  .Progressbar_progressBar__fH-Nd {\n    height: 100%;\n    text-align: center;\n    font-size: 12px;\n    font-weight: bold;\n    color: white;\n    line-height: 10px;\n    border-radius: 5px;\n    transition: width 0.5s ease-in-out;\n  }", "",{"version":3,"sources":["webpack://src/progressBar/Progressbar.module.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;IAClB,kCAAkC;EACpC","sourcesContent":[".progressBarContainer {\n    width: 100%;\n    height: 10px;\n    background-color: #e0e0e0;\n    border-radius: 5px;\n    overflow: hidden;\n    margin-top: 5px;\n  }\n  \n  .progressBar {\n    height: 100%;\n    text-align: center;\n    font-size: 12px;\n    font-weight: bold;\n    color: white;\n    line-height: 10px;\n    border-radius: 5px;\n    transition: width 0.5s ease-in-out;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"progressBarContainer": "Progressbar_progressBarContainer__7T9xs",
	"progressBar": "Progressbar_progressBar__fH-Nd"
};
export default ___CSS_LOADER_EXPORT___;
