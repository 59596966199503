import React, { useState, useRef, useEffect } from 'react';
import '../CustomerPanel.css';

const CustomerPanel = ({ userDetails, updatedLocation }) => {
    
    // If no customer info is provided, use default data
  

    const customer = userDetails ??{};
    console.log('customer', customer.UserAddress)
    

    const [recording, setRecording] = useState(false);
    const mediaRecorderRef = useRef(null);
    const [stream, setStream] = useState(null);
    const [address, setAddress] = useState('')
    console.log('address', address)
  useEffect(()=>{
    if (customer.UserAddress) {
      setAddress(customer.UserAddress)
    }
  },[customer.UserAddress])
      
    const fetchAddress = async (lat, lon) => {
        try {
          const parsedLat = parseFloat(lat);
          const parsedLon = parseFloat(lon);
          
          if (isNaN(parsedLat) || isNaN(parsedLon)) {
            throw new Error('Invalid latitude or longitude');
          }
          
          const response = await fetch(
            `https://maps.googleapis.com/maps/api/geocode/json?latlng=${parsedLat},${parsedLon}&key=AIzaSyBecG1vJF6BtYPs83IXSt3upKZzxFehuuI`
          );
          const data = await response.json();
          
          if (data.status === "OK" && data.results.length > 0) {
           
            const result = data.results[0];
            console.log('resulttt', result)
            const addressComponents = result.address_components;
            console.log('addressComponents', addressComponents)
           
            const locality = addressComponents.find(
              component => component.types.includes('locality')
            )?.long_name;
            console.log('locality', locality)
            
            const subLocality = addressComponents.find(
              component => component.types.includes('sublocality')
            )?.long_name;
            
            const neighborhood = addressComponents.find(
              component => component.types.includes('neighborhood')
            )?.long_name;
            console.log('neighborhood', neighborhood)
            // Use the most specific name available
            setAddress( result.formatted_address);
          } else {
            setAddress('Location not found');
          }
        } catch (error) {
          console.error('Error fetching address:', error);
          setAddress('Error fetching location');
        }
      };

  useEffect(() => {
        if (updatedLocation) {
          const { lat, lng } = updatedLocation;
          console.log('laat, lng', `${lat}, ${lng}`)
          // Ensure latitude and longitude are numbers before calling fetchAddress
          fetchAddress(lat, lng);
        }
      }, [updatedLocation, userDetails]);

    const startRecording = async () => {
        const displayMediaOptions = {
            video: true,
            audio: {
                echoCancellation: true,
                noiseSuppression: true,
                sampleRate: 44100,
            }
        };

        try {
            const screenStream = await navigator.mediaDevices.getDisplayMedia({
                video: true,
                audio: {
                    echoCancellation: true,
                    noiseSuppression: true,
                    sampleRate: 44100,
                },
                audio: true, // Request system audio capture
            });

            // Combining system audio with microphone audio
            const audioStream = await navigator.mediaDevices.getUserMedia({ audio: true });
            const combinedStream = new MediaStream([
                ...screenStream.getVideoTracks(),
                ...screenStream.getAudioTracks(), // Adding system audio
                ...audioStream.getAudioTracks() // Adding microphone audio
            ]);

            setStream(combinedStream);
            mediaRecorderRef.current = new MediaRecorder(combinedStream);

            mediaRecorderRef.current.ondataavailable = (event) => {
                const blob = new Blob([event.data], { type: 'video/webm' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = 'iyzilUserRecording.webm';
                document.body.appendChild(a);
                a.click();
                URL.revokeObjectURL(url);
            };

            mediaRecorderRef.current.start();
            setRecording(true);
        } catch (err) {
            if (err.name === 'NotAllowedError') {
                alert('Screen capture permission was denied. Please allow screen capture.');
            } else {
                console.error("Error: " + err);
            }
        }
    };

    const stopRecording = () => {
        mediaRecorderRef.current.stop();
        stream.getTracks().forEach(track => track.stop());
        setRecording(false);
    };

    return (
        <div className="customer-panel">
            <div className="customer-info">
                <div className="info-item">
                    <div className="info-label">Name Test</div>
                    <div className="info-value">{customer.first_name && customer.last_name ? `${customer.first_name} ${customer.last_name}` : ''}</div>
                </div>
                <div className="info-item">
                    <div className="info-label">Phone#</div>
                    <div className="info-value">{customer.contact }</div>
                </div>
                <div className="info-item">
                    <div className="info-label">Location</div>
                    <div className="info-value"> {address ? address : "Fetching location..."}

                        </div>
                </div>
                {/* ${address} */}

                {/* <div className="screen-recorder mt-auto d-flex justify-content-end" style={{ marginLeft: "auto" }}>
                    <button
                        className="screenRecorder"
                        onClick={recording ? stopRecording : startRecording}
                    >
                        {recording ? 'Stop Recording' : 'Start Recording'}
                    </button>
                </div> */}
            </div>

        </div>


    );
};

export default CustomerPanel;

