// import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
// import socketIOClient from 'socket.io-client';
// import { SOCKET_ENDPOINT } from '../config/keys.config';
// import { SOCKET_AUTHENTICATION } from '../constants/socket';
// import constants from '../constants/socket';

// const SocketContext = createContext(null);
// export const SocketProvider = ({ children }) => {
//     const socketRef = useRef(null);
//     const [isConnected, setIsConnected] = useState(false);

//     useEffect(() => {
//         // Initialize socket connection
//         socketRef.current = socketIOClient('https://stagdashboard.iylus.com', {
//             path: '/api/socket.io',
//             reconnectionAttempts: Infinity,
//             transports: ['websocket'],
//         });

//         // Connection event handlers
//         socketRef.current.on('connect', () => {
//             console.log('Socket connected successfully with backend!');
//             setIsConnected(true);
            
//             // Authenticate socket
//             socketRef.current.emit(SOCKET_AUTHENTICATION, {
//                 token: constants.socketToken,
//             });
//             console.log('Socket authenticated successfully with backend!');
//         });
    

//         socketRef.current.on('disconnect', () => {
//             console.log('Socket disconnected');
//             setIsConnected(false);
//         });

//         socketRef.current.on('connect_error', (error) => {
//             console.error('Socket connection error:', error);
//             setIsConnected(false);
//         });
//         return () => {
//         console.log('Cleaning up socket event listeners');
//         socketRef.current.off('connect');
//         socketRef.current.off('disconnect');
//         socketRef.current.off('connect_error');
//         socketRef.current.disconnect();
//         }
//     }, []);

//     // Provide both socket instance and connection status
//     const value = {
//         socket: socketRef.current,
//         isConnected
//     };

//     return (
//         <SocketContext.Provider value={value}>
//             {children}
//         </SocketContext.Provider>
//     );
// };

// export const useSocket = () => {
//     const context = useContext(SocketContext);
//     if (!context) {
//         throw new Error('useSocket must be used within a SocketProvider');
//     }
//     return context;
// };





// export const SocketProvider = ({ children }) => {
//     const socketRef = useRef(null);
//     console.log('socketRefinChild', socketRef.current)
//     useEffect(() => {
//         // Initialize socket connection
//         socketRef.current = socketIOClient('https://stagdashboard.iylus.com', {
//             path: '/api/socket.io',
//             reconnectionAttempts: 5,
//             transports: ['websocket', 'polling'],
//         });
          
//         socketRef.current.on('connect', () => {
//             console.log('Socket connected successfully with backend!');
//             socketRef.current.emit(SOCKET_AUTHENTICATION, {
//                 token: constants.socketToken,
                
//             });
//             console.log('Socket authenticated successfully with backend!');
//         });

//         socketRef.current.on('connect_error', (error) => {
//             console.error('Socket connection error:', error);
//         });

//         // return () => {
//         //     console.log('Cleaning up socket connection');
//         //     if (socketRef.current) {
//         //         socketRef.current.disconnect();
//         //     }
//         // };
//     }, []);

//     return (
//         <SocketContext.Provider value={socketRef.current}>
//             {children}
//         </SocketContext.Provider>
//     );
// };

// export const useSocket = () => useContext(SocketContext);