const {REACT_APP_SOCKET_URL,
    REACT_APP_STATIC_TOKEN,
    REACT_APP_BACKEND_URL,
    REACT_APP_VIDEO_CALL_USERNAME,
    REACT_APP_VIDEO_CALL_PASSWORD,
    REACT_APP_AGORA_API_KEY,
    REACT_APP_HEALTH_APP_URL
} = process.env
export const SOCKET_ENDPOINT = REACT_APP_SOCKET_URL;
export const STATIC_TOKEN = REACT_APP_STATIC_TOKEN;
export const BACKEND_URL = REACT_APP_BACKEND_URL
export const AGORA_USERNAME = REACT_APP_VIDEO_CALL_USERNAME;
export const AGORA_PASSWORD = REACT_APP_VIDEO_CALL_PASSWORD;
export const AGORA_API_KEY = REACT_APP_AGORA_API_KEY;
export const HEALTH_APP_URL = REACT_APP_HEALTH_APP_URL;