// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".loader_loaderContainer__mR2Pk {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n \n  }\n  \n  .loader_loader__2xvB9 {\n    width: 25px;\n    height: 25px;\n    border: 5px solid white;\n    border-top: 5px solid transparent;\n    border-radius: 50%;\n    animation: loader_spin__3B9Or 1s linear infinite;\n  }\n  \n  @keyframes loader_spin__3B9Or {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  ", "",{"version":3,"sources":["webpack://src/loader/loader.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;;EAErB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,uBAAuB;IACvB,iCAAiC;IACjC,kBAAkB;IAClB,gDAAkC;EACpC;;EAEA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF","sourcesContent":[".loaderContainer {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n \n  }\n  \n  .loader {\n    width: 25px;\n    height: 25px;\n    border: 5px solid white;\n    border-top: 5px solid transparent;\n    border-radius: 50%;\n    animation: spin 1s linear infinite;\n  }\n  \n  @keyframes spin {\n    0% {\n      transform: rotate(0deg);\n    }\n    100% {\n      transform: rotate(360deg);\n    }\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loaderContainer": "loader_loaderContainer__mR2Pk",
	"loader": "loader_loader__2xvB9",
	"spin": "loader_spin__3B9Or"
};
export default ___CSS_LOADER_EXPORT___;
