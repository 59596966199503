
// import React, { useRef } from 'react';
// import { useEffect, useState } from 'react';
// import socketIOClient from 'socket.io-client'
// import axios from 'axios';
// //Styles
// import Styles from "./header.module.css";

// import Loader from '../loader/Loader';
// //assets
// import Logo from "../assets/images/logo.svg";
// import Oxygen from "../assets/images/oxygen.svg"
// import HeartRate from "../assets/images/HeartRate.svg"
// import BloodPressure from "../assets/images/BloodPressure.svg"
// import HeartRatee from "../assets/images/HeartRatee.svg"
// import constants from '../constants/socket';

// const Header = () => {
//     const [healthVitals, setHealthVitals] = useState({
//         oxygen: "",
//         heartRate: "",
//         bodyTemperature: "",
//         bloodPressure:{
//             diastolic: "",
//             systolic: ""
//         }
//     })
//     const [socketHealthVitals, setSocketHealthVitals] = useState({
//         oxygen: "",
//         heartRate: "",
//         bodyTemperature: "",
//         bloodPressure:{
//             diastolic: "",
//             systolic: ""
//         }
//     })
    
    
    
//     const [socket, setSocket] = useState(null)
//     console.log('socket', socket)
//     //const socketRef = useRef(null)
//     const [wantData, setWantData] = useState(false)
//     const [isLoading, setIsLoading] = useState(false)
//     const [lastChecked, setLastChecked] = useState(null);
//     const [userId, setUserId] = useState(null)
//     const [timeAgo, setTimeAgo] = useState("");
//     console.log('socket healthserver', socket)
//     console.log('healthVitals', healthVitals)
//     console.log('userId', userId)
//     //I am currently
//     let userID = '67a59a8ae11e97c694cf30a1';
   
//     console.log('wantData', wantData)
//     const queryParams = new URLSearchParams(window.location.search); // Get the query string
//     const alarmId = queryParams.get('alarmId');


//     //function to fetch userId using alarmId 
//     const getUserId = async()=>{
//         try {
//             const response = await axios.get(
//                 `https://stagdashboard.iylus.com/api/v1/user/getUserForDashboard?alarmId=${alarmId}`
//               );
//               const data = response.data;
//               const userId = data.data._id;
//               setUserId(userId)
//               console.log('Id of user', userId)
//               console.log('data of user', data)
//         } catch (error) {
//             console.log('error', error)
//         }
       
//     }
   
//     useEffect(()=>{
//     getUserId()
//     },[alarmId])






//     //call health api on every refresh 
    
//     const fetchHealthVitalsOnRefresh = async()=>{
//         // const response = await axios.get(`https://staghealthapi.iylus.com/healthData/user/${userId}`)
//         // const data = response.data
//         // const dateKey = Object.keys(data)[0];
//         // const oxygen = data[dateKey][0].bloodOxygen;
//         // const bodyTemperature = data[dateKey][0].bodyTemperature;
//         // const heartRate = data[dateKey][0].heartRate
//         // const diastolic = data[dateKey][0].bloodPressure.diastolic
//         // const systolic = data[dateKey][0].bloodPressure.systolic
//         try {
            
//             const response =  await axios.get(`https://staghealthapi.iylus.com/healthData/user/${userID}`);
//             const data = response.data;
//             const dateKey = Object.keys(data)[0]; // Assuming there's only one date key
//             const records = data[dateKey]; // Get the array of records for that date
    
//             if (records.length > 0) {
//                 const latestRecord = records[records.length - 1]; // Get the last record
            
//                 const oxygen = latestRecord.bloodOxygen;
//                 const bodyTemperature = latestRecord.bodyTemperature;
//                 const heartRate = latestRecord.heartRate;
//                 const diastolic = latestRecord.bloodPressure.diastolic;
//                 const systolic = latestRecord.bloodPressure.systolic;
            
//                 setHealthVitals({
//                     oxygen: oxygen,
//                     bodyTemperature: bodyTemperature,
//                     heartRate: heartRate,
//                     bloodPressure:{
//                         diastolic:diastolic,
//                         systolic:systolic
//                     }
//                 })
//                 console.log({ oxygen, bodyTemperature, heartRate, diastolic, systolic });
//             }
//         } catch (error) {
//             console.log('error', error)
//         }
      
       
//         // setHealthVitals({
//         //     oxygen: oxygen,
//         //     bodyTemperature: bodyTemperature,
//         //     heartRate: heartRate,
//         //     bloodPressure:{
//         //         diastolic:diastolic,
//         //         systolic:systolic
//         //     }
//         // })
//         // console.log('oxygen', oxygen)
//         // console.log('bodyTemperature', bodyTemperature)
//         // console.log('heartRate', heartRate)
//         // // const data = await response.JSON()
//         // console.log('healtData', data)
//         //setHealthVitals(data)
//     }
//     const connectSocketWithHealthServer=()=>{
      
//         try {
//             const socket = socketIOClient('https://staghealthapi.iylus.com',{
//              extraHeaders: {
//                     authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYWRtaW4iLCJpYXQiOjE2OTYwMDAwMDB9.9LLyJwp7HfCd8OqO5E0pX6Q1f8Nk',
//                     "Client-Version": "v3",
//                      // Pass token in auth headers
//                 },
//                 query: { token:'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYWRtaW4iLCJpYXQiOjE2OTYwMDAwMDB9.9LLyJwp7HfCd8OqO5E0pX6Q1f8Nk' },
//                 path: '/socket.io',
//                 reconnection: true,
//                 reconnectionAttempts: 10,
//                 transports: ['websocket']
//             })
//             socket.on('connect', ()=>{
//                 console.log('connected to health server')
//                 // socket.emit('healthRequest', { userId: '12345' }, (response) => {
//                 //     console.log(`event emitted to ${userId}`, response);
//                 // });
                
                 
//                 setSocket(socket)
//                 //socketRef.current = socket
//                 //setSocket(socket)
                
               
//             })
//             // socket.emit('healthRequest', ()=>{
//             //     console.log('socket emitted successfully for health vitals')
//             //  })
//             socket.on('disconnect', ()=>{
//                 console.log('socket disconnected')
//             })
            

//             return () => {
                
//                 socket.off('disconnect');
//                 socket.close();
//               }
           
//         } catch (error) {
//             console.log('error', error)
//         }
      
       
//     }
//     const getHealthValuesOnClick=()=>{
//         setWantData(true)
//         setIsLoading(true)
//         const now = new Date();
//         setLastChecked(now);
//        if(socket && userId){

//             socket.emit('healthRequest', {isRequested: true, _id:userId},()=>{
//                 console.log(`socket emitted successfully for health vitals ${userID}`)
//             } 
              
//             )

//            socket.on('healthValuesDashboard', (data)=>{
//           console.log(`listening sockets from watch /health backend`)
//             setTimeout(() => {
//                 setSocketHealthVitals({
//                     oxygen: data.bloodOxygen,
//                     bodyTemperature: data.bodyTemperature,
//                     heartRate: data.heartRate,
//                     bloodPressure: {
//                         diastolic: data.bloodPressure.diastolic,
//                         systolic: data.bloodPressure.systolic
//                     }
//                 });
//                 setIsLoading(false);
//             }, 2000); 
            
//                 // setSocketHealthVitals({
//                 //     oxygen: 15,
//                 //     bodyTemperature: 18,
//                 //     heartRate: 20,
//                 //     bloodPressure:{
//                 //         diastolic:80,
//                 //         systolic:120
//                 //     }
//                 // })
//             })
//         }
//     }


//     const calculateTimeAgo = () => {
//         if (!lastChecked) return;
//         const now = new Date();
//         const diffInSeconds = Math.floor((now - lastChecked) / 1000);
//         if (diffInSeconds < 15) {
//             setTimeAgo(`Just now`);
//         } else if (diffInSeconds < 60) {
//             const roundedSeconds = Math.floor(diffInSeconds / 15) * 15; // Round to nearest 15 sec
//             setTimeAgo(`${roundedSeconds} sec ago`);
//         } else if (diffInSeconds < 3600) {
//             setTimeAgo(`${Math.floor(diffInSeconds / 60)} min ago`);
//         } else {
//             setTimeAgo(`${Math.floor(diffInSeconds / 3600)} hr ago`);
//         }
//         // if (diffInSeconds < 60) {
//         //     setTimeAgo(`${diffInSeconds} sec ago`);
//         // } else if (diffInSeconds < 3600) {
//         //     setTimeAgo(`${Math.floor(diffInSeconds / 60)} min ago`);
//         // } else {
//         //     setTimeAgo(`${Math.floor(diffInSeconds / 3600)} hr ago`);
//         // }
//     };

//     // Update timeAgo every minute to keep it dynamic
//     useEffect(() => {
//         calculateTimeAgo();
//         let intervalTime = lastChecked && (new Date() - lastChecked) / 1000 < 60 ? 15000 : 60000;
//         const interval = setInterval(() => {
//             calculateTimeAgo();
//         }, intervalTime);

//         return () => clearInterval(interval);
//     }, [lastChecked]);


    
// useEffect(()=>{
//     connectSocketWithHealthServer()
//      fetchHealthVitalsOnRefresh()
// },[])

  








//code with issue solve of null userId

import React, { useRef, useEffect, useState } from 'react';
import socketIOClient from 'socket.io-client';
import axios from 'axios';
import Styles from "./header.module.css";
import Loader from '../loader/Loader';
import Logo from "../assets/images/logo.svg";
import Oxygen from "../assets/images/oxygen.svg";
import HeartRate from "../assets/images/HeartRate.svg";
import BloodPressure from "../assets/images/BloodPressure.svg";
import HeartRatee from "../assets/images/HeartRatee.svg";
import constants from '../constants/socket';
import { BACKEND_URL, HEALTH_APP_URL } from '../config/keys.config';
import NormalIndicator from '../assets/images/NormalIndicator.svg';
import CriticalIndicator from '../assets/images/CriticalIndicator.svg';
import EmergencyIndicator from '../assets/images/EmergencyIndicator.svg'
import Progressbar from '../progressBar/Progressbar';
console.log('HealthAppURL in stage', HEALTH_APP_URL)


const Header = () => {
    const [healthVitals, setHealthVitals] = useState({
        oxygen: "",
        heartRate: "",
        bodyTemperature: "",
        bloodPressure: {
            diastolic: "",
            systolic: ""
        }
    });
    
    const [socketHealthVitals, setSocketHealthVitals] = useState({
        oxygen: "",
        heartRate: "",
        bodyTemperature: "",
        bloodPressure: {
            diastolic: "",
            systolic: ""
        }
    });
    
    const [socket, setSocket] = useState(null);
    const [wantData, setWantData] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [lastChecked, setLastChecked] = useState(null);
    const [userId, setUserId] = useState(null);
    const [origin, setOrigin] = useState('');
    const [data, setData] = useState(null);
    console.log('userId', userId);
    const [timeAgo, setTimeAgo] = useState("");
    console.log('isLoading', isLoading)
    console.log('wantDAta', wantData)
    
    const queryParams = new URLSearchParams(window.location.search);
    const alarmId = queryParams.get('alarmId');

   
    // Function to fetch userId using alarmId
    const getUserId = async () => {
        try {
            console.log(`${BACKEND_URL}, BAckendURL`)
            const response = await axios.get(
                `${BACKEND_URL}/api/v1/user/getUserForDashboard?alarmId=${alarmId}`
            );
            const data = response.data;
            setData(data);
            console.log('dataaaa', data)
            const newUserId = data.data._id;
            console.log('newUserID', newUserId)
            setUserId(newUserId);
            setOrigin(data.data.alarmOrigin);
            console.log('origin', data.data.alarmOrigin)
            console.log('origin', origin);
            // Fetch health vitals immediately after getting userId
            if (newUserId) {
                await fetchHealthVitalsOnRefresh(newUserId);
            }
        } catch (error) {
            console.error('Error fetching userId:', error);
        }
    };

    // Modified to accept userId as parameter
    const fetchHealthVitalsOnRefresh = async (currentUserId) => {
        try {
            const idToUse = currentUserId || userId;
            if (!idToUse) {
                console.error('No userId available for fetching health vitals');
                return;
            }

            const response = await axios.get(`${HEALTH_APP_URL}healthData/user/${idToUse}`);
            console.log('HealthAppURL in stage', HEALTH_APP_URL)

            const data = response.data;
            const dateKey = Object.keys(data)[0];
            const records = data[dateKey];

            if (records && records.length > 0) {
                const latestRecord = records[records.length - 1];
                setHealthVitals({
                    oxygen: latestRecord.bloodOxygen,
                    bodyTemperature: latestRecord.bodyTemperature,
                    heartRate: latestRecord.heartRate,
                    bloodPressure: {
                        diastolic: latestRecord.bloodPressure.diastolic,
                        systolic: latestRecord.bloodPressure.systolic
                    }
                });
            }
        } catch (error) {
            console.error('Error fetching health vitals:', error);
        }
    };

    const connectSocketWithHealthServer = () => {
        console.log('HealthAppURL in stage', HEALTH_APP_URL)

        try {
            const socket = socketIOClient(HEALTH_APP_URL, {
                extraHeaders: {  
                    authorization: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYWRtaW4iLCJpYXQiOjE2OTYwMDAwMDB9.9LLyJwp7HfCd8OqO5E0pX6Q1f8Nk',
                    "Client-Version": "v3",
                },
                query: { token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYWRtaW4iLCJpYXQiOjE2OTYwMDAwMDB9.9LLyJwp7HfCd8OqO5E0pX6Q1f8Nk' },
                path: '/socket.io',
                reconnection: true,
                reconnectionAttempts: 10,
                transports: ['websocket'],
                reconnectionDelay: 2000, // Delay before attempting reconnection
                reconnectionDelayMax: 5000,
            });
               
            socket.on('connect', () => {
                console.log('Connected to health server');
                setSocket(socket);
            });

            socket.on('disconnect', () => {
                console.log('Socket disconnected');
            });

            return () => {
                socket.off('disconnect');
                socket.close();
            };
        } catch (error) {
            console.error('Error connecting to socket:', error);
        }
    };

    const getHealthValuesOnClick = () => {
        if (!userId) {
            console.error('No userId available for health values request');
            return;
        }

        setWantData(true);
        setIsLoading(true);
        const now = new Date();
        setLastChecked(now);

        if (socket) {
            socket.emit('healthRequest', { isRequested: true, _id: userId }, () => {
                console.log(`Socket emitted successfully for health vitals ${userId}`);
            });

            socket.on(`healthValuesDashboard`, (data) => {
                console.log('Listening sockets from watch /health backend', data);
               // setTimeout(() => {
                    setSocketHealthVitals({
                        oxygen: data.bloodOxygen,
                        bodyTemperature: data.bodyTemperature,
                        heartRate: data.heartRate,
                        bloodPressure: {
                            diastolic: data.bloodPressure.diastolic,
                            systolic: data.bloodPressure.systolic
                        }
                    });
                    setIsLoading(false);
               // }, 2000);
            });
        }
    };

    const calculateTimeAgo = () => {
        if (!lastChecked) return;
        const now = new Date();
        const diffInSeconds = Math.floor((now - lastChecked) / 1000);
        
        if (diffInSeconds < 15) {
            setTimeAgo(`Just now`);
        } else if (diffInSeconds < 60) {
            const roundedSeconds = Math.floor(diffInSeconds / 15) * 15;
            setTimeAgo(`${roundedSeconds} sec ago`);
        } else if (diffInSeconds < 3600) {
            setTimeAgo(`${Math.floor(diffInSeconds / 60)} min ago`);
        } else {
            setTimeAgo(`${Math.floor(diffInSeconds / 3600)} hr ago`);
        }
    };

    // Initial setup
    useEffect(() => {
        if (alarmId) {
            getUserId();
        }
    }, [alarmId]);

    // Socket connection
    useEffect(() => {
        connectSocketWithHealthServer();
    }, []);

    // Time ago calculator
    useEffect(() => {
        calculateTimeAgo();
        let intervalTime = lastChecked && (new Date() - lastChecked) / 1000 < 60 ? 15000 : 60000;
        const interval = setInterval(calculateTimeAgo, intervalTime);
        return () => clearInterval(interval);
    }, [lastChecked]);

  



    return (
        <>
        {
     data && data!==null ? (<>   <header className={Styles.header}>
        <a href="/" className={Styles.logo}>
            <img src={Logo} alt="EagleEye AI Logo" className={Styles.Logo} />
        </a>
        {
            origin && origin === 'Panic Alarm' ? (''):(<>  {
                wantData ? (  <div className={Styles.wrapperHealthVitals}>
           
                    <div className={Styles.independentHealthValues}>
                       <div><img src={Oxygen}/></div>
                       <div><h4 className={Styles.HeaderHealthValues}>Oxygen <span style={{display: 'block'}} className={Styles.values}>{`${socketHealthVitals.oxygen} %`} </span></h4>
                       {/* <p className={Styles.values}>96%</p> */}
                       </div>
                    </div>
           
                    <div className={Styles.independentHealthValues}>
                       <div><img src={HeartRate}/></div>
                       <div><h4 className={Styles.HeaderHealthValues}>Heart Rate <span style={{display: 'block'}} className={Styles.values}> {`${socketHealthVitals.heartRate}  BPM`}  </span></h4>
                       {/* <p  className={Styles.values}>72 &nbsp;BPM</p> */}
                       </div>
                    </div>
           
                    <div className={Styles.independentHealthValues}>
                       <div><img src={BloodPressure}/></div>
                       <div><h4 className={Styles.HeaderHealthValues}>Blood Pressure <span style={{display: 'block'}} className={Styles.bloodPressureValues}> {`${socketHealthVitals.bloodPressure.systolic} - ${socketHealthVitals.bloodPressure.diastolic} (mmhg)`} </span></h4>
                       {/* <p  className={Styles.values}>65 &nbsp; mmhg</p> */}
                       </div>
                    </div>
                    <div className={Styles.independentHealthValues}>
                       <div><img src={HeartRatee}/></div>
                       <div><h4 className={Styles.HeaderHealthValues}>Body Temperature <span style={{display: 'block'}} className={Styles.values}>{`${socketHealthVitals.bodyTemperature}  C`}</span></h4>
                       {/* <p  className={Styles.values}>37  C</p> */}
                       </div>
                    </div>
                </div>):(  <div className={Styles.wrapperHealthVitals}>
           
                    <div className={Styles.independentHealthValues}>
                       <div><img src={Oxygen}/></div>
                       <div><h4 className={Styles.HeaderHealthValues}>Oxygen <span style={{display: 'block'}} className={Styles.values}>{`${healthVitals.oxygen}  %`} </span></h4>
                      <Progressbar value={healthVitals.oxygen} />
                       {/* <p className={Styles.values}>96%</p> */}
                       </div>
                    </div>
           
                    <div className={Styles.independentHealthValues}>
                       <div><img src={HeartRate}/></div>
                       <div><h4 className={Styles.HeaderHealthValues}>Heart Rate <span style={{display: 'block'}} className={Styles.values}> {`${healthVitals.heartRate}  BPM`}  </span></h4>
                       <Progressbar value={healthVitals.heartRate}/>
                       {/* <p  className={Styles.values}>72 &nbsp;BPM</p> */}
                       </div>
                    </div>
           
                    <div className={Styles.independentHealthValues}>
                       <div><img src={BloodPressure}/></div>
                       <div><h4 className={Styles.HeaderHealthValues}>Blood Pressure <span style={{display: 'block'}} className={Styles.bloodPressureValues}> {`${healthVitals.bloodPressure.systolic} - ${healthVitals.bloodPressure.diastolic} (mmhg)`} </span></h4>
                       <Progressbar/>
                       {/* <p  className={Styles.values}>65 &nbsp; mmhg</p> */}
                       </div>
                    </div>
                    <div className={Styles.independentHealthValues}>
                       <div><img src={HeartRatee}/></div>
                       <div><h4 className={Styles.HeaderHealthValues}>Body Temperature <span style={{display: 'block'}} className={Styles.values}>{`${healthVitals.bodyTemperature}  C`}</span></h4>
                       <Progressbar value={healthVitals.bodyTemperature}/>
                       {/* <p  className={Styles.values}>37  C</p> */}
                       </div>
                    </div>
                </div>)
            }</>)
        }
      

{/* perfect written code */}
        {/* <div className={Styles.wrapperHealthVitals}>
           
     <div className={Styles.independentHealthValues}>
        <div><img src={Oxygen}/></div>
        <div><h4 className={Styles.HeaderHealthValues}>Oxygen <span style={{display: 'block'}} className={Styles.values}>{healthVitals.oxygen} </span></h4>
     
        </div>
     </div>

     <div className={Styles.independentHealthValues}>
        <div><img src={HeartRate}/></div>
        <div><h4 className={Styles.HeaderHealthValues}>Heart Rate <span style={{display: 'block'}} className={Styles.values}> {healthVitals.heartRate}  </span></h4>
       
        </div>
     </div>

     <div className={Styles.independentHealthValues}>
        <div><img src={BloodPressure}/></div>
        <div><h4 className={Styles.HeaderHealthValues}>Blood Pressure <span style={{display: 'block'}} className={Styles.bloodPressureValues}> {`${healthVitals.bloodPressure.diastolic} - ${healthVitals.bloodPressure.systolic} (mmhg)`} </span></h4>
      
        </div>
     </div>
     <div className={Styles.independentHealthValues}>
        <div><img src={HeartRatee}/></div>
        <div><h4 className={Styles.HeaderHealthValues}>Body Temperatur <span style={{display: 'block'}} className={Styles.values}>{healthVitals.bodyTemperature}</span></h4>

        </div>
     </div>
 </div> */}


{origin === 'Panic Alarm' ? ('') : (<> <div className={Styles.wrapperRecheckCase}>
    <div><p style={{fontWeight: '500', fontSize: '14px', color: '#103A5E'}} >Last Checked  <span style={{display: 'block', fontSize: '14px', fontWeight: '300', marginLeft: '16px', color: '#7C7C7C'}} >{`${timeAgo}`?`${timeAgo}`:'Not check'}</span> </p>
   </div>
   {isLoading?  <button className={Styles.rechekBtn} > <Loader/> </button>: <button className={Styles.rechekBtn} onClick={getHealthValuesOnClick}>Recheck</button>}
    {/* <button className={Styles.rechekBtn} onClick={getHealthValuesOnClick}>Recheck</button> */}
 </div>
 
 <div className={Styles.healthVitalsIndicators}>
    <div className={Styles.IndicatorsValues}><img src={NormalIndicator}/>Normal</div>
    <div className={Styles.IndicatorsValues}><img src={CriticalIndicator}/>Critical</div>
    <div className={Styles.IndicatorsValues}><img src={EmergencyIndicator}/>Emergency</div>
 </div>
 </>)}
 

    </header></>):('')
   
        }
    {/* <div className={Styles.healthVitalsIndicators}>
    <div className={Styles.IndicatorsValues}><img src={NormalIndicator}/>Normal</div>
    <div className={Styles.IndicatorsValues}><img src={CriticalIndicator}/>Critical</div>
    <div className={Styles.IndicatorsValues}><img src={EmergencyIndicator}/>Emergency</div>
 </div> */}
      
     </>
    )
}

export default Header;